import EspecialistaService from '../../services/EspecialistaService'
import LocalidadeService from '../../services/LocalidadeService'

const state = () => ({
    especialistas: [],
    loadingEspecialista: false,
    loadingMoreEspecialista: false,
    cidadePesquisada: '',
    pages:  0,
    page: 1,
    dadosBusca: JSON.parse(localStorage.getItem('dadosBusca')) ? {estado: JSON.parse(localStorage.getItem('dadosBusca')).estado, cidade: ""} : { estado: "", cidade: ""}
})

const mutations = {
  SET_DADOS_BUSCA: (state, dadosBusca) => {
    localStorage.setItem('dadosBusca', JSON.stringify(dadosBusca));
    state.dadosBusca = dadosBusca;
  },
  SET_ESPECIALISTAS: (state, payload) => {
    state.especialistas = payload.especialistas;
    state.pages = payload.pages;
    state.page = payload.page;
  },
  SET_MORE_ESPECIALISTAS: (state, payload) => {
    state.especialistas = [...state.especialistas, ...payload.especialistas]
    state.pages = payload.pages;
    state.page = payload.page;
  },
  SET_LOADING_ESPECIALISTAS: (state, payload) => {
    state.loadingEspecialista = payload
  },
  SET_LOADING_MORE_ESPECIALISTAS: (state, payload) => {
    state.loadingMoreEspecialista = payload
  },
  CLEAR_CIDADE(state) {
    state.dadosBusca.cidade = ""
  },
  CLEAR_BUSCA(state) {
    state.especialistas = []
    state.page = 1;
    state.pages = 0;
    state.cidadePesquisada = '';
    state.radius = 10;
  },
  SET_CIDADE_PESQUISADA(state, city) {
    state.cidadePesquisada = city
  }
}
  
const actions = {
  async carregarEspecialista({ commit }, {lat, lng, page}) {
    try {
      commit('SET_LOADING_ESPECIALISTAS', true);
      const response = await EspecialistaService.getBuscarEspecialistas(lat, lng, page);
      commit('SET_ESPECIALISTAS', response.data)
      commit('SET_LOADING_ESPECIALISTAS', false);

    } catch (error) {
      commit('SET_LOADING_ESPECIALISTAS', false);
      console.error('Erro na geocodificação:', error);
    }
  },
  async carregarMoreEspecialista({ commit }, {lat, lng, page}) {
    try {
      commit('SET_LOADING_MORE_ESPECIALISTAS', true);
      const response = await EspecialistaService.getBuscarEspecialistas(lat, lng, page);
      setTimeout(() => {
        commit('SET_MORE_ESPECIALISTAS', response.data)
        commit('SET_LOADING_MORE_ESPECIALISTAS', false);
      }, 1000);

    } catch (error) {
      commit('SET_LOADING_MORE_ESPECIALISTAS', false);
      console.error('Erro na geocodificação:', error);
    }
  },
  async geocodeEndereco({ commit }, enderecoEspecialista) {
    try {
      commit('SET_LOADING_ESPECIALISTAS', true);
      const response = await LocalidadeService.getGeocoding(enderecoEspecialista);
      const { results } = response.data;
      if (results.length > 0) {
        const { lat, lng } = results[0].geometry.location;
        commit('SET_LOADING_ESPECIALISTAS', false);
        return ({lat, lng})
      }
    } catch (error) {
      commit('SET_LOADING_ESPECIALISTAS', false);
      console.error('Erro na geocodificação:', error);
    }
  }
}

const getters = {
  getCidadePesquisada: state => state.cidadePesquisada,
  getEspecialistas: state => state.especialistas,
  getLoadingEspecialista: state => state.loadingEspecialista,
  getLoadingMoreEspecialista: state => state.loadingMoreEspecialista,
  getTotalPaginasEspecialistas: state => state.pages,
  getDadosBusca: state => state.dadosBusca,
  getPage: state => state.page
}

export default {
  state,
  getters,
  mutations,
  actions
};