import Api from './Api'
import axios from 'axios'

export default {
    getEstadosIBGE() {
        return Api().get('https://servicodados.ibge.gov.br/api/v1/localidades/estados');
    },
    getEstados(){
        return Api().get(`/states`);
    },
    getCidades(idUF) {
        return axios.get(`${process.env.VUE_APP_API_GEO_LOCATION}/api/nearbyCities/estado?idUF=${idUF}`);
    },
   
}