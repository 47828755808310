import LocalidadeService from '../../services/LocalidadeService'

const state = () => ({
  estados: JSON.parse(localStorage.getItem('estados')) || [],
  cidades: JSON.parse(localStorage.getItem('municipios')) || [],
  center: { lat: -16, lng: - 58 }
})

const mutations = {
    SET_ESTADOS: (state, estados) => {
      localStorage.setItem('estados', JSON.stringify(estados));
      state.estados = estados;
    },
    SET_CIDADES: (state, cidades) => {
      localStorage.setItem('municipios', JSON.stringify(cidades));
      state.cidades = cidades;
    },
    SET_MAP_CENTER: (state, {lat, lng}) => {
      state.center = { lat, lng };
    },
}
  
const actions = {
  async carregarEstadosIBGE({ commit }) {
    const response = await LocalidadeService.getEstadosIBGE();
    commit('SET_ESTADOS', response.data.sort((a, b) => a.nome.localeCompare(b.nome)))
  },
  async carregarCidades({ commit }, idEstado) {
    const response = await LocalidadeService.getCidades(idEstado);
    commit('SET_CIDADES', response.data)
  },
}

const getters = {
  getEstados: state => state.estados,
  getCidades: state => state.cidades,
  getCenter: state => state.center,
  getRadius: state => state.radius,
}

export default {
  state,
  getters,
  mutations,
  actions
};